'use client';
import { useCallback, useRef } from 'react';

const VersionBanner: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const timerRef = useRef<number | null>(null);

  const downEventHandler = useCallback(() => {
    timerRef.current = window.setTimeout(() => {
      alert('Version: ' + process.env.NEXT_PUBLIC_VERSION_BANNER);
    }, 5000);
  }, []);

  const upEventHandler = useCallback(() => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }
  }, []);

  return (
    <div onPointerDown={downEventHandler} onPointerUp={upEventHandler}>
      {children}
    </div>
  );
};

export default VersionBanner;
